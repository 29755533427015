<template>   
        <ODataLookup
            :dataObject="dsComponenents"
            :bind="sel=>select(sel)"
            :value="currentComponent"
            class="form-select form-select-sm"
            :placeholder="$t('Choose component')">
            <OColumn field="ComponentName"></OColumn>
        </ODataLookup>
  
        <o-col-container title="Code Builder" v-if="currentIndex > -1"  :key="currentIndex">
             <div id="codebuilderTreeTreeSizer">
                <div class="d-flex flex-column flex-1">
                    <Marked class="flex-1" :value="currentBuilder.markdown" style="overflow-y: auto;"></Marked>
                </div>
                <div class="d-flex flex-column flex-1">
                    <Marked class="flex-1" :value="currentBuilder.markdownCode" style="overflow-y: auto;"></Marked>
                </div>
             </div>
          
             <o-sizer-panel width="60%" id="codebuilderMarkedTreeSizer" >
                <CBTree :builder="currentBuilder"></CBTree>
                <p class="mx-2">{{currentBuilder.treeObject.currentNode.item.description}}</p>
                <CodeBuilderConfig :builder="currentBuilder" :key="currentIndex" />
            </o-sizer-panel>
        </o-col-container >
</template>
<script setup>
import {ref, watch} from 'vue';
import {dsComponenents} from 'o365.modules.CodeBuilder.constants.ts'
import CBTree from 'o365.vue.components.CodeBuilder.Tree.vue'

import Marked from 'o365.vue.components.Marked.vue';
import CodeBuilder from 'o365.modules.CodeBuilder.ts';
import CodeBuilderConfig from 'o365.vue.components.CodeBuilder.Config.vue';

const props = defineProps({
    dataObjects:{
        required: false
    }
});

const currentIndex = ref(-1);
const builders = [];

let currentBuilder = null;
const currentComponent = ref(null);

const select = (item) => {
    let vIndex = builders.findIndex(x=>x["fileName"] === item.FileName);
    currentComponent.value = item.ComponentName;
    if(vIndex == - 1){
        const vBuilder = {
            fileName:item.FileName,
            builder : ref(new CodeBuilder("codebuilder_"+item.FileName,props.dataObjects))
        }
        builders.push(vBuilder);
        vBuilder.builder.value.addNode({
            fileName:item.FileName,
            count:0,
            id:item.FileName
        }).then(()=>{
            vBuilder.builder.value.treeObject.currentNode = vBuilder.builder.value.treeObject.node.children[0];
            currentBuilder = vBuilder.builder;
            currentIndex.value = builders.length-1;
        });        
    } else {
        currentIndex.value = vIndex;
        currentBuilder = builders[vIndex].builder;
    }
}
</script>
